import React from 'react'
import Logo from '../components/Logo'
import Layout from '../components/Layout'
import Helmet from 'react-helmet'
import SEO from '../components/SEO'
import config from '../utils/siteConfig'
import Socials from '../components/Socials'
import Container from '../components/Container'

const Index = ({ data, pageContext }) => {
  const { currentPage } = pageContext

  return (
    <Layout>
      <SEO />
      <Helmet>
        <title>{`${config.siteTitle}`}</title>
      </Helmet>
      <Container>
        <Logo />
        <Socials />
      </Container>
    </Layout>
  )
}

export default Index
