import React from 'react'
import styled from 'styled-components'
import { FaInstagram, FaYoutube, FaTwitter, FaGithub } from 'react-icons/fa'

const SocialList = styled.div`
  width: 100%;
  max-width: ${props => props.theme.sizes.maxWidth};
  margin: 0 auto;
  padding: 0.5em;
  font-size: 50px;
  text-align: center;
  text-decoration: none;
`

const SocialLink = styled.a`
  &&& {
    color: black;
    padding: 0.2em;
  }
`

const Socials = () => {
  return (
    <SocialList>
      <SocialLink href="https://www.instagram.com/littlehobbyshop" target="_blank" rel="noopener" aria-label="Go to Little Hobby Shop on Instagram">
        <FaInstagram/>
      </SocialLink>
      <SocialLink href="https://twitter.com/littlehobbyshop" target="_blank" rel="noopener" aria-label="Go to Little Hobby Shop on Twitter">
        <FaTwitter />
      </SocialLink>
      {/*
      <SocialLink href="https://www.youtube.com/channel/UCUkxnOWtXAkpBpqLgR0pYPQ" target="_blank" rel="noopener">
        <FaYoutube />
      </SocialLink>
      */}
      <SocialLink href="https://github.com/littlehobbyshop" target="_blank" rel="noopener" aria-label="Go to Little Hobby Shop on Github">
        <FaGithub/>
      </SocialLink>
    </SocialList>
  )
}

export default Socials
