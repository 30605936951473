import React from 'react'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { StaticQuery, graphql } from 'gatsby'

const BgImg = styled(Img)`
  position: absolute;
  top: 0;
  left: 0;
  max-height: 300px;
  & > picture > img {
    object-fit: contain !important;
    margin: 0 auto
  }
`

const Logo = () => (
  <StaticQuery
    query={graphql`
      query {
        allContentfulImage {
          edges {
            node {
              title
              slug
              image {
                id
                title
                fluid(maxHeight: 300, quality: 100) {
                  ...GatsbyContentfulFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <BgImg
        fluid={data.allContentfulImage.edges[0].node.image.fluid}
        style={{background: "#ffffff"}}
  />
    )}
  />
)


export default Logo
